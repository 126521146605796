import * as React from 'react'

export const OverviewItem = ({title, image, link }) => (
  <div className='overview__item'> 
    <div className='overview__item-wrapper'>
      <a href={link}>
        <img loading='lazy' src={image}></img>
      </a>
      <div className='overview__item-description'>
        <a href={link}><div className='overview__item-description-title'>{title}</div></a>
      </div>
    </div>
  </div>
)