import * as React from 'react'
import { TERRACE } from '../images/images'
import travelmytop20 from '../images/travelmytop20.png'
import booking from '../images/Digital-Award-TRA-2023.png'
import travelmythsmall from '../images/travelmythsmall.png'

const backgroundImageStyle = {
  backgroundSize: 'cover',
  backgroundImage:`url(${TERRACE[1]})`,
  backgroundRepeat: 'no-repeat',
}

export const Reviews = (locale)=>(
  <div className='reviews__section-wrapper'>
    <div className='reviews_background-image' style={backgroundImageStyle}>
      <div className='reviews__overlay'>
        
        <div className='score__wrapper'>
          <div className='score_background-image'></div>
          <div className='score__cards-container'>
            <div className='score__card'>
              <img loading='lazy' width={'45%'} src={booking}></img>
            </div>
            <div className='score__card'>
              <img loading='lazy' width={'45%'} src={travelmytop20}></img>
            </div>
            <div className='score__card'>
              <img loading='lazy' width={'45%'} src={travelmythsmall}></img>
            </div>
          </div>
        </div>
        
      </div>    
    </div>
  </div>
)