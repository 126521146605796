import React from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import {TERRACE} from '../images/images'
import {MOBILE} from '../images/images'

const properties = {
  infinite: true,
  indicators: true,
  scale: 1.4,
  arrows: false,
  pauseOnHover: false,
  transitionDuration: 300
}

const LARGEST_IMAGE_ARRAY = TERRACE.length > MOBILE.length ? TERRACE : MOBILE
export const Slideshow = ()=> {
  return (
    <div className='slider-container'>
      <Slide {...properties}>
        {LARGEST_IMAGE_ARRAY.map((image, idx) => {
          const IMAGE_DESKTOP = TERRACE[idx] ? TERRACE[idx] : TERRACE[idx % TERRACE.length]
          const IMAGE_MOBILE = MOBILE[idx] ? MOBILE[idx] : MOBILE[idx % MOBILE.length]  
          return (
            <div key={idx} className='slider__picture-container'>
              <picture className='slider__picture'>
                <source srcSet={IMAGE_DESKTOP} media='(min-width: 800px)' />
                <img loading={idx===0? 'eager' : 'lazy'} className='slider__image' src={IMAGE_MOBILE}></img>
              </picture>
            </div>
          )
        })
        }
      </Slide>
    </div>
  )
}