import * as React from 'react'
import '../style/common.css'
import '../style/large.css'
import { getInitLanguage } from '../services/i18n.service'
import 'lightgallery.js/dist/css/lightgallery.css'
import { Footer } from '../components/Footer'
import { STUDIO, STANDARD} from '../images/images'
import { ContactForm } from '../components/ContactForm'
import { Header } from '../components/Header'
import { OverviewItem } from '../components/OverviewItem'
import { Reviews } from '../components/Reviews'
import { Location } from '../components/Location'
import { Slideshow } from '../components/Slideshow'
import { WelcomeSection } from '../components/WelcomeSection'
import {IoIosArrowUp} from 'react-icons/io'
import {SEO} from '../components/seo'
import {FiPhone} from 'react-icons/fi'
import {BiEnvelope} from 'react-icons/bi'

// styles
const pageStyles = {
  color: '#484848',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const IndexPage = () => {
  const [locale, setLocale] = React.useState(getInitLanguage())

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
 

  React.useEffect(()=>{
    setLocale(getInitLanguage())
  }, [])  

  return <main style={pageStyles}>

    <SEO />
    <Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} 
      locale={locale} setLocale={setLocale}>  
    </Header>
    <a className='anchor' id='top'></a>

    <Slideshow></Slideshow> 
  
    <a className='mouse smoothscroll' href='#scroll-to'>
      <div className='mouse-icon'>
        <span className='mouse-wheel' id='scroll-to'></span>
      </div>
    </a>
    <WelcomeSection locale={locale}></WelcomeSection>

    <a className='anchor' id='overview'></a>
    <div className='gray-bg'>
      <div id='items'>
      
        <OverviewItem title={locale.double} image={STANDARD[0]} link={'standard'} locale={locale}>
        </OverviewItem>
        <OverviewItem title={locale.studio} image={STUDIO[0]} link={'studio'} locale={locale}>
        </OverviewItem>
      </div>
    </div>
    <a className='anchor' id='reviews'></a>
    <Reviews locale={locale}></Reviews>

    <a className='anchor' id='location'></a>
    <Location locale={locale}></Location>

    <a className='anchor' id='contact'></a>
    <h1> {locale.get_in_touch} </h1>
    <h3>
      <FiPhone style={{verticalAlign:'middle'}} />
      <a target='_blank' href={'tel:+302284032013'}> (+30) 22840-32013</a>
      <span style={{margin:'0 10px'}}>&</span>
      <a target='_blank' href={'tel:+306949908072'}> (+30) 69499-08072</a>
    </h3>
    <h3>
      <BiEnvelope style={{verticalAlign:'middle'}}/>
      <a target='_blank' href={'mailto:mkaridi@gmail.com?subject=Mail from margaritasifnos.gr'}> mkaridi@gmail.com</a>
    </h3>
    <ContactForm locale={locale}></ContactForm>  
    <a className='top-arrow' href='#top'>
      <IoIosArrowUp></IoIosArrowUp>
    </a>
    <Footer></Footer>
  </main>
}


export default IndexPage
