import mobile1 from './mobile/1.png'
import mobile2 from './mobile/2.png'
import mobile3 from './mobile/3.png'
import mobile4 from './mobile/4.png'
import mobile5 from './mobile/5.png'
import mobile6 from './mobile/6.png'

import standard1 from './standard/0.jpg'
import standard2 from './standard/1.jpg'
import standard3 from './standard/2.jpg'
import standard4 from './standard/3.jpg'
import standard5 from './standard/4.jpg'
import standard6 from './standard/5.jpg'
import standard7 from './standard/6.jpg'
import standard8 from './standard/7.jpg'
import standard9 from './standard/8.jpg'
import standard10 from './standard/9.jpg'
import standard11 from './standard/10.jpg'
import standard12 from './standard/11.jpg'
import standard13 from './standard/12.jpg'
import standard14 from './standard/13.jpg'

import terrace1 from './terrace/0.jpg'
import terrace2 from './terrace/1.jpg'
import terrace3 from './terrace/4.jpg'
import terrace4 from './terrace/3.jpg'
import terrace5 from './terrace/2.jpg'
import terrace7 from './terrace/6.jpg'
import terrace8 from './terrace/7.jpg'
import terrace9 from './terrace/8.jpg'
import terrace10 from './terrace/9.jpg'
import terrace11 from './terrace/10.jpg'
import terrace12 from './terrace/11.jpg'
import terrace13 from './terrace/13.jpg'

import studio1 from './studio/0.jpg'
import studio2 from './studio/1.jpg'
import studio3 from './studio/2.jpg'
import studio4 from './studio/3.jpg'
import studio5 from './studio/4.jpg'
import studio6 from './studio/5.jpg'
import studio7 from './studio/6.jpg'
import studio8 from './studio/7.jpg'
import studio9 from './studio/8.jpg'

export const TERRACE = [
  terrace2,
  terrace3,
  terrace4,
  terrace5,
  terrace7,
  terrace8,
  terrace9,
  terrace10,
  terrace1,
  terrace11,
  terrace12,
  terrace13
]

export const MOBILE = [
  mobile1,
  mobile2, 
  mobile3,
  mobile4,
  mobile5,
  mobile6
]

export const STANDARD = [
  standard1,
  standard2,
  standard3,
  standard4,
  standard5,
  standard6,
  standard7,
  standard8,
  standard9,
  standard10,
  standard11,
  standard12,
  standard13,
  standard14,
]
export const STUDIO = [
  studio1,
  studio2,
  studio3,
  studio4,
  studio5,
  studio6,
  studio7,
  studio8,
  studio9
]