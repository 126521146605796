import * as React from 'react'

export const Location = (locale)=>{
  locale = locale.locale
  return <>
    <div className='location-wrapper gray-bg'>   
      <div className='location-description'>
        <h2>{locale.location}</h2>
        <p className='margin-bottom-l'>{locale.location_text}</p> 
        <a className='button__link' href='/location'> {locale.how_to_reach}</a>
      </div>
      <div className='location-iframe'>
        <iframe  width={'100%'} height={'450'} frameBorder={'0'} style={{border:0}} allowFullScreen
          // eslint-disable-next-line max-len
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3187.7208130465997!2d24.71744518196348!3d36.96872413605712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0xd8da8fac8049c726!2sMargarita+Karidi!5e0!3m2!1sde!2sde!4v1457453561360'>
        </iframe>
      </div>
    </div>
  </>
}