import * as React from 'react'
import {TERRACE, STANDARD} from '../images/images'

export const WelcomeSection = (locale) =>{
  return <div className='welcome__screen-wrapper'>
    <div className='welcome__screen-images-wrapper'>
      <div className='welcome__screen-top-image'>
        <img loading='lazy' src={STANDARD[4]}></img>
      </div>
      <div className='welcome__screen-bottom-image'>
        <img loading='lazy' style={{width:'80%'}} src={TERRACE[3]}></img>
      </div>
    </div>
    <div className='welcome__screen-text-wrapper'>
      <div className='welcome__screen-text-title'>
        <h2> {locale.locale.welcome_text_title}</h2>
      </div>
      <div className='welcome__screen-text-description-wrapper'>
        <p className='welcome__screen-text-description'> {locale.locale.welcome_text}</p>
      </div>
    </div>
  </div>
}